<template>
  <div
    id="test"
    @click="hide"
    class="fixed inset-0 z-30 flex flex-col items-center w-screen h-screen justify-evenly bg-primary"
    :class="theme"
  >
    <div
      class="relative w-1/2 overflow-hidden border-4 rounded-full md:border-8 border-secondary md:w-1/3 md:pb-1/3 pb-1/2"
    >
      <img
        class="absolute top-0 object-cover object-center w-full h-full"
        :src="image"
      />
    </div>
    <h1
      dir="rtl"
      class="w-2/3 px-2 text-3xl font-black text-center text-white md:text-4xl lg:text-5xl font-mine"
    >
      {{ name }}
    </h1>
    <h1
      dir="rtl"
      class="w-2/3 px-2 text-xl font-black text-center text-white md:text-2xl lg:text-3xl font-mine"
    >
      {{ branch }}
    </h1>
    <div
      class="relative w-2/3 bg-white border-4 pb-2/3 md:border-8 md:w-1/2 md:pb-1/2 border-secondary rounded-mine"
    >
      <img
        class="absolute top-0 object-cover object-center w-full h-full p-2"
        :src="codeLink"
      />
    </div>
    <h1
      class="px-2 mb-4 text-3xl font-black text-center text-white md:text-4xl lg:text-5xl font-mine"
    >
      امسح الكود
    </h1>
  </div>
</template>

<script>
export default {
  mounted: function () {
    var elem = document.getElementById("test");

    this.color = getComputedStyle(elem)
      .getPropertyValue("--color-secondary")
      .substring(1);
    this.codeLink =
      "https://api.qrserver.com/v1/create-qr-code/?size=400x400&color=" +
      this.color +
      "&data=https://cosine.store/" +
      this.link;
  },
  name: "QR",
  props: {
    link: String,
    theme: String,
    name: String,
    branch: String,
    image: String,
  },
  methods: {
    hide() {
      this.$emit("hide");
    },
  },
  data() {
    return {
      color: "",
      codeLink: "",
    };
  },
};
</script>

